import {
  CPMMOrderType,
  CPMMConfigField,
  VaultConfigField,
  CpmmPricingStrategy
} from '../../../types'

export default {
  vaultConfig: {
    nav: 'Config',
    tickSize: 'Tick Size',
    title: 'Update config',
    baseConfig: 'Base Config',
    priceRange: 'Price range',
    vaultConfig: 'Vault Config',
    noVaultsFound: 'No vaults found.',
    marketMakingConfig: 'Market Making Config',
    instantiate: {
      title: 'Instantiate vault'
    },
    toast: {
      updateCPMMVault: 'Successfully updated CPMM vault config',
      updateSpotVault: 'Successfully updated spot vault config',
      updateOffChainVault: 'Successfully updated Off-chain vault config',
      updateDerivativeVault: 'Successfully updated derivative vault config',
      instantiateCPMMVault: 'Successfully instantiated cpmm vault',
      instantiateSpotVault: 'Successfully instantiated spot vault',
      instantiateOffChainVault: 'Successfully instantiated OffChain vault',
      instantiateDerivativeVault: 'Successfully instantiated derivative vault'
    },

    orderType: {
      [CPMMOrderType.PostOnly]: 'Post only',
      [CPMMOrderType.Vanilla]: 'Allow taker'
    },

    [VaultConfigField.Address]: {
      title: 'Address',
      placeholder: 'inj...'
    },
    [VaultConfigField.NotionalValueCap]: {
      placeholder: '100000',
      title: 'Notional value cap',
      description:
        "Maximum notional value of vault's funds, once it's reached no new subscriptions will be allowed"
    },
    [VaultConfigField.OracleStaleTime]: {
      title: 'Oracle stale time',
      description: 'Time in seconds until an oracle price is considered stale'
    },
    [VaultConfigField.PositionPnLPenalty]: {
      title: 'Position PnL penalty',
      description:
        'A constant that determines what price to use when evaluation a position PNL compared to mark price, e.g. 0 would imply using mark price and 2 would imply 0.98 or 1.02 * mark price'
    },
    [VaultConfigField.AllowedDerivativeRedemptionType]: {
      title: 'Allowed derivative redemption types'
    },
    [VaultConfigField.OracleType]: {
      title: 'Oracle type',
      description: 'The oracle type to use for the market'
    },
    [VaultConfigField.BaseOracleSymbol]: {
      title: 'Base oracle symbol'
    },
    [VaultConfigField.QuoteOracleSymbol]: {
      title: 'Quote oracle symbol'
    },
    [VaultConfigField.BaseDecimals]: {
      title: 'Base decimals'
    },
    [VaultConfigField.QuoteDecimals]: {
      title: 'Quote decimals'
    },
    [VaultConfigField.OrderDensity]: {
      placeholder: '10',
      title: 'Order density',
      description:
        'Number of orders to place between the head and the tail on each side'
    },
    [VaultConfigField.FeeRecipient]: {
      title: 'Fee recipient',
      placeholder: 'inj...',
      description: 'The fee recipient address in the orders'
    },
    [VaultConfigField.MarketId]: {
      title: 'Market',
      placeholder: 'Select market'
    },
    [VaultConfigField.ReservationPriceSensitivityRatio]: {
      title: 'Reservation price sensitivity ratio',
      description:
        'A constant between 0..1 that will be used to control the sensitivity of the reservation_price from mid_price'
    },
    [VaultConfigField.ReservationSpreadSensitivityRatio]: {
      title: 'Reservation spread sensitivity ratio',
      description:
        'A constant between 0..1 that will be used to control the sensitivity of the spread around the reservation_price'
    },
    [VaultConfigField.MaxActiveCapitalUtilizationRatio]: {
      title: 'Max active capital utilization ratio',
      description:
        'A constant between 0..1 that will be used to determine what percentage of how much of our total deposited balance we want margined on the book or in positions'
    },
    [VaultConfigField.HeadChangeToleranceRatio]: {
      title: 'Head change tolerance ratio',
      description:
        'A constant between 0..1 that serves as a threshold for when we actually want to take action in the new block'
    },
    [VaultConfigField.HeadToTailDeviationRatio]: {
      title: 'Head to tail deviation ratio',
      description:
        'A constant between 0..1 that determines the distance between head and tail'
    },
    [VaultConfigField.SignedMinHeadToFairPriceDeviationRatio]: {
      title: 'Signed min head to fair price deviation ratio',
      description:
        'A constant that ensures head is at least some distance from fair price, or if negative not more than defined, e.g. 0.1 = 10% minimum distance, or -0.1 = not more than 10% beyond fair price'
    },
    [VaultConfigField.SignedMinHeadToTobDeviationRatio]: {
      title: 'Signed min head to tob deviation ratio',
      description:
        'A constant that ensures head is at least some distance from tob, or if negative not more than defined, e.g. 0.1 = 10% minimum distance, or -0.1 = not more than 10% beyond TOB. A recommended value is (-1 * min_price_tick_size)'
    },
    [VaultConfigField.MinOracleVolatilitySampleSize]: {
      title: 'Min oracle volatility sample size',
      description:
        'Minimum sample size for oracle volatility. If we are below this number we will use the default'
    },
    [VaultConfigField.OracleVolatilityMaxAge]: {
      title: 'Oracle volatility max age',
      description:
        'Time in seconds of which oracle values will be taken into account for volatility calculations'
    },
    [VaultConfigField.DefaultMidPriceVolatilityRatio]: {
      title: 'Default mid price volatility ratio',
      description:
        'A constant between 0..1 that when multiplied by mid price gives us the skewed volatility'
    },
    [VaultConfigField.MinVolatilityRatio]: {
      title: 'Min volatility ratio',
      description:
        'The minimum volatility we will use for our volatility calculations'
    },
    [VaultConfigField.EmergencyOracleVolatilitySampleSize]: {
      title: 'Emergency oracle volatility sample size',
      description:
        'Minimum sample size threshold for oracle emergency. If we are below this number we will cancel all'
    },
    [VaultConfigField.LastValidMarkPrice]: {
      title: 'Last valid mark price',
      description:
        'The last valid registered mark price tracked by the contract to protect for oracle failures'
    },
    [VaultConfigField.Leverage]: {
      title: 'Leverage',
      description: 'Leverage that a contract will use on its orders'
    },
    [VaultConfigField.MinProximityToLiquidation]: {
      title: 'Min proximity to liquidation',
      description:
        'A constant that represents the minimum proximity to liquidation we are willing to tolerate based on position margin in relation to maintenance margin'
    },
    [VaultConfigField.TargetBaseWeight]: {
      title: 'Target base weight',
      description:
        'A constant target weight for a target balance between base vs. quote asset'
    },
    [VaultConfigField.AllowedSpotRedemptionType]: {
      title: 'Allowed spot redemption type'
    },
    [VaultConfigField.Label]: {
      title: 'Vault Label',
      description: 'Name of the vault'
    },
    [VaultConfigField.WithdrawalAdminFeeBps]: {
      title: 'Withdrawal admin fee',
      placeholder: '0.01',
      description: 'The withdrawal admin fee percentage, capped at 10%'
    },
    [VaultConfigField.InjRewardCommissionBps]: {
      title: 'INJ reward commission bps',
      placeholder: '1',
      description: 'The reward commission fee percentage, capped at 10%'
    },
    [CPMMConfigField.MaxPriceSensitivityBps]: {
      placeholder: '0.05',
      title: 'Max price sensitivity bps',
      description:
        'The max change in price before force replacement of all orders.'
    },
    [CPMMConfigField.MaxInvariantSensitivityBps]: {
      placeholder: '0.05',
      title: 'Max invariant sensitivity bps',
      description:
        'The max change in root of invariant before force replacement of all orders.'
    },
    [CPMMConfigField.FeeBps]: {
      title: 'Fee spread',
      placeholder: '0.01',
      description: 'Fee spread percentage, capped at 10%'
    },
    [CPMMConfigField.PricingStrategy]: {
      title: 'Pricing strategy',
      description: 'The pricing strategy to use for the CPMM.'
    },
    [CpmmPricingStrategy.ConstantPricingWithTickSize]: {
      title: 'Constant pricing with tick size',
      placeholder: '0.001',
      description:
        'The size of price ticks for the CPMM, must be a multiple of the base tick size.'
    },
    [CPMMConfigField.PricingStrategyBidRange]: {
      title: 'Bid Range',
      placeholder: '0.01'
    },
    [CPMMConfigField.PricingStrategyAskRange]: {
      title: 'Ask Range',
      placeholder: '0.01'
    },
    [CpmmPricingStrategy.SmoothingPricingWithRelativePriceRange]: {
      title: 'Smoothing pricing with relative price range',
      placeholder: '0.01',
      description:
        "The relative price range for the CPMM, must be between 0 and 1, but realistically shouldn't be too close to 1."
    },
    [CPMMConfigField.OrderType]: {
      title: 'Select order type',
      placeholder: 'Post only'
    }
  }
}
