import {
  IDOStatus,
  IDOProjectField,
  VestingSchedule,
  I18nMessageFunction,
  LaunchpadFilterType,
  LaunchpadCountdownStatus
} from '../../../types'

export default {
  launchpad: {
    wagmi: 'WAGMI!',
    underMaintenance: 'Under Maintenance',
    description: 'Participate in new project launches and bootstrap markets',
    feedbackDescription: ({ named, interpolate }: I18nMessageFunction) =>
      interpolate([
        'Please note that this launch is for testing purposes only and no real funds need to be used. Any and all of your feedback will play a major role in improving Mito even more. Please use the link ',
        named('here'),
        ' to provide feedback.'
      ]),
    common: {
      date: 'Date',
      price: 'Price',
      supply: 'Supply',
      vested: 'Vested',
      mitoBlog: 'Mito Blog',
      stakeINJ: 'Stake INJ',
      learnMore: 'Learn More',
      subscribed: 'Subscribed',
      multiplier: 'Multiplier'
    },
    filter: {
      [LaunchpadFilterType.AllProjects]: 'All projects',
      [LaunchpadFilterType.LiveProjects]: 'Upcoming & Live',
      [LaunchpadFilterType.Oldest]: 'Oldest to latest',
      [LaunchpadFilterType.Latest]: 'Recent to oldest'
    },
    table: {
      startsIn: 'Starts in',
      endsIn: 'Ends in',
      token: 'Token',
      salePrice: 'Sale price',
      tokenOffered: 'Token Offered',
      filled: 'Filled'
    },
    progress: {
      final: 'Final',
      target: 'Target',
      current: 'Current'
    },
    countdown: {
      [LaunchpadCountdownStatus.Starting]: 'The sale starts in:',
      [LaunchpadCountdownStatus.Live]: 'The sale is live',
      [LaunchpadCountdownStatus.Ended]: 'The sale has ended',
      [IDOStatus.Allocating]:
        'Subscription has ended and calculation in progress.',
      [IDOStatus.Allocated]:
        'Calculation completed and the tokens are ready to claim.',
      [IDOStatus.AllocatedFailed]:
        'Undersubscribed, refunded tokens are ready to claim',
      ended: 'Ended',
      claimTokens: 'Claim Tokens',
      subscribeNow: 'Subscribe Now',
      fullySubscribed: 'Fully Subscribed',
      subscriptionEnded: 'Subscription has ended',
      notWhitelisted: 'Not Whitelisted',
      tokensClaimed: 'Tokens Claimed',
      goToVault: ({ named }: I18nMessageFunction) =>
        `Go To ${named('symbol')} Vault`,
      saleLiveNote: ({ named }: I18nMessageFunction) =>
        `Subscription is still available when target is reached! All subscribers will receive ${named(
          'symbol'
        )} proportionately based on the amount committed.`
    },
    timeline: {
      title: 'Subscription timeline',
      [IDOStatus.Announced]: 'Project announced',
      [IDOStatus.Started]: 'Subscription begins',
      [IDOStatus.Allocated]: 'Allocate and claim tokens',
      [IDOStatus.Allocating]: 'Subscription ends',
      [IDOStatus.AllocatedFailed]: 'Undersubscribed'
    },
    details: {
      subscribers: 'Subscribers',
      projectOwner: 'Project Owner',
      salePrice: 'Sale Price',
      totalSale: 'Total sale',
      vaultToken: 'VaultToken',
      title: 'Project details',
      tokensOffered: 'Tokens Offered',
      targetRaisedAmount: 'Target Raise Amount',
      subscriptionRequirements: 'Subscription requirements',
      subscriptionRequirementsDescription: ({ named }: I18nMessageFunction) =>
        `Whitelisted addresses provided by the ${named(
          'name'
        )} team are eligible to subscribe to the Launchpad`,
      hardCapPerUser: 'Maximum Contribution Per User',
      receivingTokenFormat: 'Receiving Token Format',
      receivingTokensFormatNoVault: ({
        named
      }: I18nMessageFunction) => `*After subscription ends, subscribers will receive the full allocation of
      ${named('symbol')} tokens. No Mito vault will be
      automatically created.`,
      receivingTokenNonLpFormatDescription: ({ named }: I18nMessageFunction) =>
        `100% ${named('symbol')}`,
      receivingTokenFormatDescription: ({ named }: I18nMessageFunction) =>
        `50% ${named('symbol')} Token + 50% ${named('lpSymbol')} Token`,
      receivingTokenFormatTooltip: ({ named }: I18nMessageFunction) =>
        `50% of the assets raised through the sale will be added to the ${named(
          'baseSymbol'
        )}-${named(
          'quoteSymbol'
        )} vault to bootstrap its liquidity. In return, users will receive LP tokens of the ${named(
          'baseSymbol'
        )}-${named(
          'quoteSymbol'
        )} vault. In addition, subscribers will be receiving ${named(
          'baseSymbol'
        )} and project owner will be receiving ${named('quoteSymbol')}.`,
      priceInUSD: ({ named }: I18nMessageFunction) =>
        `(Price in ${named('symbol')} will be determined ${named(
          'duration'
        )} prior to the start of the subscription)`,
      staked: {
        title: 'Stake requirement for subscription',
        description:
          'The amount each address can subscribe is based on the amount of INJ staked.',
        stakedInj: 'Staked INJ',
        maximumSubscriptionAmount: 'Max subscription amount'
      },
      subscriptionStyle: {
        title: 'Subscription style',
        description: 'Overflow',
        tooltip:
          "If there is an oversubscription, the subscriber's final allocation will be based on the amount of INJ the subscriber committed as a percentage of the INJ committed by all subscribers in Subscription ends stage. Any unused INJ will be able to be claimed by subscribers in Allocation stage"
      },
      howItWorks: {
        title: 'How it works',
        description1a: ({ named }: I18nMessageFunction) =>
          `Mito Launchpad allows users to obtain ${named(
            'symbol'
          )}, tokens before it is listed.`,
        description1b: ({ named }: I18nMessageFunction) =>
          `The Mito Launchpad also uniquely bootstraps liquidity for the ${named(
            'symbol'
          )}/INJ market  as soon as its live.`,
        description2: ({ named }: I18nMessageFunction) =>
          `When the launchpad opens, users can subscribe into the ${named(
            'symbol'
          )} pool using INJ. The launchpad will lock up the INJ you used to subscribe to the sale until the sale closes. If the project hits their minimum raise target, then the sale completes and moves onto the next phase. Conversely, if the target is not met, then all users who participated can claim back their INJ.`,
        description3: ({ named }: I18nMessageFunction) =>
          `Assuming that the sale successfully concluded, users will be able to claim the ${named(
            'symbol'
          )} tokens. In addition, users will also receive LP tokens. They can either redeem the LP tokens for ${named(
            'symbol'
          )} and INJ or they can keep their LP tokens in the ${named(
            'symbol'
          )}/INJ market to earn yield.`,
        description4: ({ named, interpolate }: I18nMessageFunction) =>
          interpolate([
            'To learn more about the Launchpad, visit the ',
            named('mitoBlog'),
            '.'
          ])
      },
      disclaimer: {
        title: 'Disclaimer',
        description: ({ named }: I18nMessageFunction) =>
          `The content on this page was created and posted solely by the ${named(
            'projectName'
          )} team.  It is for informational purposes only and is not financial advice or an endorsement of any project or application.  The Injective Foundation is not affiliated with ${named(
            'projectName'
          )} team. The Injective Foundation has not contributed to, assisted in, or endorsed this content.`
      },
      allocation: {
        title: 'Allocation and claim your allocation',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam et velit odio. Integer nisl arcu, vulputate vel gravida vitae, rutrum nec enim. Nunc sit amet est in enim malesuada lobortis id ut metus. Cras ut ex ac orci sollicitudin facilisis. Integer vehicula mollis vulputate. '
      }
    },
    subscribers: {
      title: 'Project subscribers',
      header: 'Subscribers',
      table: {
        title: 'Historical Subscription',
        receivingLP: 'Receiving LP',
        receivingTokens: 'Receiving Tokens',
        subscribedAmount: 'Subscribed amount'
      }
    },
    subscription: {
      title: 'Your Subscription',
      claim: 'Claim',
      claimed: 'Claimed',
      claimAll: 'Claim all',
      allocation: 'Allocation',
      totalClaimed: 'Total claimed',
      claimedAmount: 'Claimed amount',
      tokensClaimed: 'Tokens claimed',
      tokenPurchased: 'Token purchased',
      claimableAmount: 'Claimable amount',
      header: 'Your subscription details',
      committedAmount: 'Committed amount',
      subscribeAmount: 'Subscribe amount',
      subscribedAmount: 'Subscribed amount',
      yourStakedAmount: 'Your staked amount',
      availableToClaim: 'Available to claim',
      subscriptionAmount: 'Subscription amount',
      yourStakedINJAmount: 'Your staked INJ amount',
      empty: 'Your subscription will be shown here',
      remainingVestingTime: 'Remaining vesting time',
      stakeMoreToSubscribe: 'Stake more to subscribe!',
      maxSubscriptionAmount: 'Max subscription amount',
      tokensAvailableToClaim: 'Tokens available to claim',
      currentSubscribedAmount: 'Current subscribed amount',
      tokensClaimedOn: ({ named }: I18nMessageFunction) =>
        `Tokens claimed on ${named('date')}`,
      stakeAmountOrMore: ({ named }: I18nMessageFunction) =>
        `${named('amount')} ${named('symbol')} or more`,
      subscribedAmountToBeRefundedOverSubscribe: ({
        named
      }: I18nMessageFunction) =>
        `${named('symbol')} returned due to oversubscription`,
      subscribedAmountToBeRefundedUndersubscribed: ({
        named
      }: I18nMessageFunction) =>
        `${named('symbol')} returned due to undersubscription`,
      claimVestingRewardDescription: ({ named }: I18nMessageFunction) =>
        `Amount of ${named('symbol')} claimed will be based on the
        vesting schedule.`,
      claimVestingRewardDescription2: ({
        named
      }: I18nMessageFunction) => `If there are remaining
        ${named('symbol')} unvested, you may return to this page
        to claim again.`,
      table: {
        title: 'Historical Subscription',
        receivingLP: 'Receiving LP',
        receivingTokens: 'Receiving Tokens',
        subscribedAmount: 'Subscribed amount'
      },
      status: {
        oversubscribed: 'Oversubscribed',
        undersubscribed: 'Undersubscribed',
        raiseProgressTitle: 'Amount (Target/Total committed)',
        [IDOStatus.Created]: 'Created',
        [IDOStatus.Cancelled]: 'Cancelled',
        [IDOStatus.Announced]: 'Announced',
        [IDOStatus.Started]: 'Live',
        [IDOStatus.Allocating]: 'Subscription ended',
        [IDOStatus.Allocated]: 'Subscription ended',
        [IDOStatus.AllocatedFailed]: 'Undersubscribed'
      },
      balance: 'Available Balance'
    },
    salesResults: {
      title: 'Sales results',
      totalCommittedAmount: 'Total committed amount',
      oversubscribedBy: 'Oversubscribed by',
      underSubscribedBy: 'Undersubscribed by',
      totalParticipants: 'Total participants',
      actionDescription: 'Sign the following messages to complete the sale',
      finalize: 'Finalize',
      finalizeDescription: 'Finalize the launchpad project & results',
      finalizeToast: 'Finalize IDO successfully!'
    },
    whitelist: {
      title: 'Whitelist',
      add: 'Add',
      remove: 'Remove',
      cancel: 'Cancel',
      download: 'Download',
      searchAddress: 'Search address...',
      downloadAddresses: 'Download addresses',
      addAddresses: 'Add addresses',
      removeAddresses: 'Remove addresses',
      revoke: {
        title: 'Revoke existing subscription?',
        description:
          'Subscription of addresses removed from the whitelist will be revoked and their subscribed INJ will be refunded back to the users.'
      },
      toast: {
        whitelistUpdated: 'Updated whitelist accounts successfully!'
      }
    },
    modal: {
      backToProject: 'Back to Project',
      reviewSubscription: 'Review Subscription',
      launchpadAgreement: 'launchpad agreement',
      exceedMaxSubscriptionAmount: 'Exceeds max subscription amount',
      acknowledge: {
        title: 'By subscribing to this launchpad, you agree to the following:',
        description: ({ named, interpolate }: I18nMessageFunction) =>
          interpolate([
            'Mito Launchpad is permissionless and users launching tokens and vaults through Mito have no relationship or affiliation with Us (as defined in the Mito Terms of Use).  We do not endorse or recommend any particular token or vault.  If you proceed to subscribe to a launch, you do so entirely at your own risk and should do your own research.  By proceeding, you acknowledge that you have read and agree to be bound by the Mito ',
            named('link'),
            '.'
          ])
      },
      claimReward: {
        title: 'Claimed successfully!',
        successToastWithClaimedCoins: ({
          named,
          interpolate
        }: I18nMessageFunction) =>
          interpolate(['You have claimed ', named('coins'), '.'])
      },
      stakeINJDescription: ({ named }: I18nMessageFunction) =>
        `The total amount you can subscribe into this launchpad project is directly tied to the amount of INJ you stake. This launchpad project requires a minimum of ${named(
          'minStakedAmount'
        )} INJ staked and you currently only have ${named(
          'stakedAmount'
        )} INJ staked.`,
      stakeInjOnInjectiveHub: 'Stake INJ on Injective Hub',
      launchpadAgreementDescription: ({
        named,
        interpolate
      }: I18nMessageFunction) =>
        interpolate([
          'I have read and agree to the ',
          named('launchpadAgreement'),
          '.'
        ]),
      title: ({ named }: I18nMessageFunction) =>
        `$${named('symbol')} Launchpad Subscription`,
      titleConfirmation: ({ named }: I18nMessageFunction) =>
        `Confirm $${named('symbol')} Subscription`,
      successMessage: ({ named }: I18nMessageFunction) =>
        `You have subscribed ${named('amount')} ${named(
          'quoteSymbol'
        )} into the $${named('baseSymbol')} launchpad.`,
      confirmationNote: ({ named }: I18nMessageFunction) =>
        `Once you have successfully subscribed, your ${named(
          'quoteSymbol'
        )} will be temporarily locked for the sale. You can redeem tokens when the subscription period ends. You will be able to claim any unused ${named(
          'quoteSymbol'
        )}.`
    },
    toast: {
      idoNotFound: 'IDO project not found'
    }
  },

  idoProject: {
    create: {
      title: 'By creating this launchpad, you agree to the following:',

      description: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          'Mito Launchpad is permissionless and, by using Launchpad, you do not create any relationship or affiliation with Us (as defined in the Mito Terms of Use).  By proceeding, you acknowledge that you have read and agree to be bound by the Mito ',
          named('link'),
          '.'
        ]),
      cta: 'Create Launchpad'
    },
    announce: {
      title: 'Announce project',
      cancelTitle: 'Cancel project',
      description:
        'Provide the start and end time below to announce the IDO to the public, note that you will not be able to retrieve the funds once the IDO announced.',
      cancelledDescription:
        'Project has been cancelled and locked funds are refunded.',
      cancelDescription:
        'You can withdraw your funds by cancelling this IDO project.',
      feeDescription: ({ named }: I18nMessageFunction) =>
        `A factory token denom creation fee of ${named(
          'fee'
        )} INJ is required. Please ensure you have sufficient balance.`,
      cta: 'Announce',
      cancel: 'Cancel'
    },
    form: {
      detailsTitle: 'Launchpad details',
      inWallet: ({ named }: I18nMessageFunction) =>
        `In wallet: ${named('amount')} ${named('symbol')}`,
      tokenPrice: {
        title: 'Token price',
        tooltip:
          'Token price is dynamic and reflects market value; total supply is the maximum number of tokens, while market cap is the total value of all tokens currently in circulation.'
      },
      creationFee: {
        title: 'Launchpad & vault creation fee',
        tooltip:
          'It covers vault creation and initial order placements. Ensure your balance meets this fee.'
      },
      receivingTokenFormat: {
        title: 'Receiving token format for project owner'
      },
      maximumContribution: {
        title: 'Maximum contribution'
      },
      sales: {
        tokenPrice: 'Token price',
        totalTargetRaiseAmount: 'Total target raise amount',
        assetsIn: 'Assets in ',
        yesIConfirm: 'Yes, I confirm the amount.',
        receivingTokenFormatForProjectOwner:
          'Receiving token format for project owner'
      }
    },
    summary: {
      title: 'View summary',
      description:
        'In order to deploy the contract and launch the launchpad, sign the following signatures to complete the creation process',
      toast: 'Launchpad created successfully'
    },
    updateDescription: {
      successToast: 'Project description updated successfully!',
      cta: 'Update'
    },

    vestingType: {
      title: 'Select vesting type',
      [VestingSchedule.PiecewiseLinear]: 'Piecewise Linear',
      [VestingSchedule.SaturatingLinear]: 'Saturating Linear'
    },

    [IDOProjectField.ProjectTokenDenom]: {
      title: 'Token Denom',
      placeholder: 'Enter token denom',
      description: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          'Enter the token contract address on Injective. ',
          named('learnMore'),
          '.'
        ])
    },
    idoDate: {
      title: 'IDO date',
      placeholder: 'Select date range',
      description: 'Pick date and time to the start the project'
    },
    [IDOProjectField.ListingFee]: {
      title: 'Instantiation fee',
      description: ({ named }: I18nMessageFunction) =>
        `An instantiation fee of ${named(
          'amount'
        )} INJ is required. Please make sure you have sufficient balance for creating this IDO project.`
    },
    [IDOProjectField.ProjectTokenTicker]: {
      title: 'Token ticker',
      placeholder: 'Ticker'
    },
    [IDOProjectField.Description]: {
      title: 'Project description',
      description: 'A brief description of the project.',
      placeholder: 'Project description'
    },
    [IDOProjectField.Website]: {
      title: 'Project link/website',
      placeholder: 'https://'
    },
    [IDOProjectField.Twitter]: {
      title: 'Twitter handle',
      placeholder: 'https://twitter.com'
    },
    [IDOProjectField.ProjectTokenAmount]: {
      title: 'Total token for sale',
      placeholder: 'Enter amount'
    },
    [IDOProjectField.HardCap]: {
      title: 'Maximum contribution per address',
      optionalTitle: 'Maximum contribution per address (optional)',
      placeholder: '0'
    },
    [IDOProjectField.TargetRaiseAmount]: {
      title: 'Target raise amount',
      description: 'Total targeted amount to raise for the project',
      placeholder: '0'
    },
    [IDOProjectField.LockPriceDurationBeforeStart]: {
      title: 'Lock time before start',
      description: 'Quote price lock time before the start of the project',
      placeholder: '3600'
    },
    [IDOProjectField.StartTimestamp]: {
      title: 'Start date',
      placeholder: 'Select date'
    },
    [IDOProjectField.EndTimestamp]: {
      title: 'End date',
      placeholder: 'Select date'
    },
    [IDOProjectField.QuotePythPriceFeedId]: {
      title: 'Quote pyth price feed id'
    },
    [IDOProjectField.UseWhitelist]: {
      title: 'Enable whitelist',
      description:
        "Only whitelisted addresses will be able to subscribe to this launchpad. You can add addresses directly on the launchpad page once it's created. If no addresses are added, subscriptions will be unavailable to all users.",
      enabledDescription:
        'Only whitelisted addresses can subscribe to the project',
      disabledDescription: 'Anyone can subscribe to the project'
    },
    [IDOProjectField.IsEnableVestingSchedule]: {
      title: 'Enable vesting schedule',
      enabledDescription: 'Rewards will be distributed overtime',
      disabledDescription: 'Rewards will be distributed with one claim'
    },
    [IDOProjectField.IsLaunchingWithVault]: {
      title: 'Launch with vault',
      enabledDescription:
        'CPMM vault will be created automatically after finalizing the IDO',
      disabledDescription:
        'CPMM vault has to be instantiated manually after finalizing the IDO'
    },
    [IDOProjectField.IsRemoveStakingRequirement]: {
      title: 'Remove staking requirement',
      enabledDescription:
        'Users can subscribe to the project without staking INJ',
      disabledDescription:
        'User has to stake INJ before subscribing to the project'
    },
    [IDOProjectField.SaturatingLinearDuration]: {
      title: 'Vesting duration',
      placeholder: '2500',
      description: 'Duration to distribute tokens'
    },
    [IDOProjectField.SaturatingLinearDelay]: {
      title: 'Vesting delay',
      placeholder: '2500',
      description: 'Delay to distribute tokens'
    },
    [IDOProjectField.PiecewiseLinearFirstVestTime]: {
      title: 'First vest time',
      placeholder: '3600'
    },
    [IDOProjectField.PiecewiseLinearFirstVestAmount]: {
      title: 'First vest amount to distribute',
      placeholder: '50'
    },
    [IDOProjectField.PiecewiseLinearSecondVestTime]: {
      title: 'Second vest time',
      placeholder: '3600'
    },
    [IDOProjectField.PiecewiseLinearSecondVestAmount]: {
      title: 'Second vest amount to distribute',
      placeholder: '50'
    }
  }
}
