import {
  StateTree,
  PiniaPluginContext,
  SubscriptionCallback,
  SubscriptionCallbackMutationPatchObject
} from 'pinia'
import { StatusType } from '@injectivelabs/utils'
import { Wallet } from '@injectivelabs/wallet-ts'
import { defineNuxtPlugin } from '#imports'
import { localStorage } from '@/app/Service'

const stateToPersist = {
  app: {
    owner: false,
    devMode: false,
    userPreference: {
      showMyVaultsOnly: false,
      skipExperimentalModal: false,
      showLowPerformingVaults: false
    }
  },
  sharedGeo: {
    geoCountry: '',
    geoContinent: ''
  },
  mission: {
    userPartOfGalxeCredentials: false
  },
  sharedWallet: {
    wallet: Wallet.Metamask,
    addresses: '',
    address: '',
    session: '',
    injectiveAddress: '',
    addressConfirmation: ''
  }
} as Record<string, Record<string, any>>

const actionsThatSetWalletQueueToBusy = [
  'vote/vote',
  'vote/addWhitelistedAddresses',
  'vote/removeWhitelistedAddresses',
  'mito/redeem',
  'mito/instantiateCPMMVault',
  'mito/subscribeToSpotVault',
  'mito/updateCPMMVaultConfig',
  'mito/instantiateSpotVault',
  'mito/updateSpotVaultConfig',
  'mito/updateOffChainVaultConfig',
  'mito/subscribeToDerivativeVault',
  'mito/instantiateDerivativeVault',
  'mito/updateDerivativeVaultConfig',
  'mito/instantiatePermissionlessCPMMVault',
  'swap/submitAtomicOrder',
  'swap/submitAtomicOrderExactOutput',
  'wallet/connectWallet',
  'staking/stake',
  'staking/unstake',
  'staking/claimStake',
  'staking/claimRewards',
  'staking/allocateGauge',
  'staking/claimAllRewards',
  'staking/updateStakingContractConfig',
  'staking/updateAllocatorContractConfig',
  'launchpad/finalizeIDO',
  'launchpad/instantiateIDO',
  'launchpad/subscribeToIDO',
  'launchpad/claimIDORewards',
  'launchpad/launchSpotMarket',
  'launchpad/claimVestedRewards',
  'launchpad/grantOneTimeAllowance',
  'launchpad/addWhitelistedAccounts',
  'launchpad/updateProjectDescription',
  'launchpad/instantiateVestingClaim',
  'launchpad/removeWhitelistedAccounts',
  'launchpad/queryUserHasOneTimeAllowance'
]

const persistState = (
  mutation: SubscriptionCallbackMutationPatchObject<StateTree>,
  state: StateTree
) => {
  if (!stateToPersist[mutation.storeId]) {
    return
  }

  const keysToPersist = Object.keys(stateToPersist[mutation.storeId])

  if (!mutation.payload) {
    return
  }

  const shouldPersistState =
    keysToPersist.length > 0 &&
    Object.keys(mutation.payload).some((key) => {
      return keysToPersist.includes(key)
    })

  if (!shouldPersistState) {
    return
  }

  const updatedState = keysToPersist.reduce((stateObj, key) => {
    return {
      ...stateObj,
      [key]: mutation.payload[key] || state[key]
    }
  }, {})

  const existingState = (localStorage.get('state') || {}) as any

  localStorage.set('state', {
    ...stateToPersist,
    ...existingState,
    [mutation.storeId]: updatedState
  })
}

function piniaStoreSubscriber({ store }: PiniaPluginContext) {
  const localState = localStorage.get('state') as any
  const sharedWalletStore = useSharedWalletStore()

  if (localState[store.$id]) {
    store.$state = { ...store.$state, ...localState[store.$id] }

    // temp fix: wallet is not enabled/available bug
    if (store.$id === 'sharedWallet') {
      store.$state = {
        ...store.$state,
        wallet: store.$state.wallet || Wallet.Metamask
      }
    }
  }

  store.$subscribe(persistState as SubscriptionCallback<StateTree>)

  store.$onAction(({ name, store: { $id }, after, onError }) => {
    after(() => {
      if (actionsThatSetWalletQueueToBusy.includes(`${$id}/${name}`)) {
        sharedWalletStore.$patch({
          queueStatus: StatusType.Idle
        })
      }
    })

    onError(() => {
      if (actionsThatSetWalletQueueToBusy.includes(`${$id}/${name}`)) {
        sharedWalletStore.$patch({
          queueStatus: StatusType.Idle
        })
      }
    })
  }, true)
}

export default defineNuxtPlugin(
  ({
    vueApp: {
      config: { globalProperties }
    }
  }) => {
    globalProperties.$pinia.use(piniaStoreSubscriber)
  }
)
