import { IS_DEVNET, IS_TESTNET } from '@shared/utils/constant'
import gitVersion from './gitVersion.json'
import devnetTokens from '@/app/json/tokens/devnet.json'
import testnetTokens from '@/app/json/tokens/testnet.json'
import mainnetTokens from '@/app/json/tokens/mainnet.json'
import restrictedCountriesJson from '@/app/json/restrictedCountries.json'
import blacklistedAddressesJson from '@/app/json/blacklistedAddresses.json'

export const getTokens = () => {
  if (IS_DEVNET) {
    return devnetTokens
  }

  if (IS_TESTNET) {
    return testnetTokens
  }

  return mainnetTokens
}

export const gitBuild = () => {
  return (
    gitVersion || {
      branch: 'master',
      tag: 'v0.0.0',
      gitTagLink: '',
      logs: []
    }
  )
}

export const restrictedCountries = restrictedCountriesJson
export const blacklistedAddresses = blacklistedAddressesJson

export const tokens = getTokens()
