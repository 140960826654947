import { defineNuxtPlugin } from '#app'
import { defineRule } from 'vee-validate'
import { NUMBER_REGEX } from '@shared/utils/constant'
import { BigNumberInBase } from '@injectivelabs/utils'
import { getEthereumAddress } from '@injectivelabs/sdk-ts'
import { UrlType } from '@/types'

export const errorMessages = {
  exceedCharacters: (fieldName: string, value: string) =>
    `${fieldName} exceeded ${value} characters limit.`,
  incorrectFormat: () => 'Incorrect format',
  maxBalance: () => 'Insufficient balance',
  maxBalanceCapped: () => 'Maximum amount capped',
  maxUsdAmount: (amount: string) => `Amount must be under $${amount}`,
  invalidUrlFormat: () => 'Invalid URL format',
  [UrlType.Twitter]: () => 'Invalid twitter link',
  [UrlType.Discord]: () => 'Invalid discord link',
  [UrlType.Telegram]: () => 'Invalid telegram link'
} as Record<string, any>

const isTypeUrlValid = (value: string, type?: UrlType) => {
  let urlTemplate = ''

  if (!type) {
    return true
  }

  if (type === UrlType.Twitter) {
    urlTemplate = 'https://twitter.com/'
  }

  if (type === UrlType.Discord) {
    urlTemplate = 'https://discord.invite.link/'
  }

  if (type === UrlType.Telegram) {
    urlTemplate = 'https://t.me/'
  }

  return value.startsWith(urlTemplate) && value.length > urlTemplate.length
}

const formatFieldName = ({
  field,
  label
}: {
  field: string
  label?: string
}) => {
  if (label) {
    return label
  }

  if (field.includes('-')) {
    return field.replaceAll('-', ' ')
  }

  return field.replace(/[^a-z]+/gi, '')
}

export const defineGlobalRules = () => {
  defineRule(
    'positiveNumber',
    (
      value: string | number,
      [label]: [string | undefined],
      { field }: { field: string }
    ) => {
      if (value === '' || isNaN(Number(value))) {
        return `${formatFieldName({ field, label })} is required`
      }

      if (Number(value) < 0) {
        return `${formatFieldName({ field, label })} cannot be smaller than 0`
      }

      return true
    }
  )

  defineRule(
    'required',
    (
      value: string | number,
      [label]: [string | undefined],
      { field }: { field: string }
    ) => {
      if (!value || !value.toString().length || Number(value) === 0) {
        if (field.toLowerCase().includes('amount')) {
          return 'amount is required'
        }

        return `${formatFieldName({ field, label })} is required`
      }

      return true
    }
  )

  defineRule('injAddress', (value: string) => {
    const errorMessage = 'Invalid INJ address'

    try {
      if (getEthereumAddress(value)) {
        return true
      } else {
        return errorMessage
      }
    } catch (error: any) {
      return errorMessage
    }
  })

  defineRule('email', (value: string) => {
    const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

    if (!emailPattern.test(value)) {
      return 'Invalid email format'
    }

    return true
  })

  defineRule('isNumber', (value: string) => {
    if (value && !NUMBER_REGEX.test(value)) {
      return errorMessages.incorrectFormat()
    }

    return true
  })

  defineRule(
    'min',
    (
      value: string,
      [min, label]: [number | string, string | undefined],
      { field }: { field: string }
    ) => {
      const fieldName = formatFieldName({ field, label })
      const valueInNumber = Number.isNaN(value) && value ? value.length : value
      const valueInBigNumber = new BigNumberInBase(valueInNumber)

      if (valueInBigNumber.lt(min)) {
        return `${label || fieldName} cannot be smaller than ${min}`
      }

      return true
    }
  )

  defineRule(
    'max',
    (
      value: string,
      [max, label]: [number | string, string | undefined],
      { field }: { field: string }
    ) => {
      const fieldName = formatFieldName({ field, label })
      const valueInNumber = Number.isNaN(value) && value ? value.length : value
      const valueInBigNumber = new BigNumberInBase(valueInNumber)

      if (valueInBigNumber.gt(max)) {
        return `${label || fieldName} cannot be greater than ${max}`
      }

      return true
    }
  )

  defineRule(
    'minToken',
    (
      value: string,
      [min, decimalPlaces]: [number | string, number | undefined],
      { field }: { field: string }
    ) => {
      const valueInNumber = Number.isNaN(value) && value ? value.length : value
      const valueInBigNumber = new BigNumberInBase(valueInNumber)

      const minInToken = sharedToBalanceInToken({
        value: min,
        decimalPlaces
      })

      if (valueInBigNumber.lt(min)) {
        return `${formatFieldName({ field })} cannot be smaller than ${minInToken}`
      }

      return true
    }
  )

  defineRule(
    'maxToken',
    (
      value: string,
      [max, decimalPlaces]: [number | string, number | undefined],
      { field }: { field: string }
    ) => {
      const valueInNumber = Number.isNaN(value) && value ? value.length : value
      const valueInBigNumber = new BigNumberInBase(valueInNumber)

      const maxInToken = sharedToBalanceInToken({
        value: max,
        decimalPlaces
      })

      if (valueInBigNumber.gt(max)) {
        return `${formatFieldName({ field })} cannot be greater than ${maxInToken}`
      }

      return true
    }
  )

  defineRule(
    'maxAllowedUsdAmount',
    (value: string, [max, usdPrice]: [string, string | undefined]) => {
      const usdAmountInBigNumber = new BigNumberInBase(value).multipliedBy(
        usdPrice || 0
      )

      if (usdAmountInBigNumber.gt(max)) {
        return errorMessages.maxUsdAmount(max)
      }

      return true
    }
  )

  defineRule('maxBalance', (value: string, max: number[]) => {
    if (!value) {
      return true
    }

    if (new BigNumberInBase(value).gt(max[0])) {
      return errorMessages.maxBalance()
    }

    return true
  })

  defineRule('maxBalanceCapped', (value: string, max: number[]) => {
    if (!value) {
      return true
    }

    if (new BigNumberInBase(value).gt(max[0])) {
      return errorMessages.maxBalanceCapped()
    }

    return true
  })

  defineRule('url', (value: string, [type]: [UrlType]) => {
    const urlRegex =
      /^https:\/\/(?:www\.)?(?:[-a-zA-Z0-9@:%._+~#=]{1,256}\.)+[a-zA-Z]{2,}\/?$/

    if (value.trim() === '') {
      return true
    }

    if (!urlRegex.test(value)) {
      return errorMessages.invalidUrlFormat()
    }

    if (type && !isTypeUrlValid(value, type)) {
      return errorMessages[type]()
    }

    return true
  })
}

export default defineNuxtPlugin(() => {
  defineGlobalRules()
})
