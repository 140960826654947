import { I18nMessageFunction } from './../../../types'

export default {
  vote: {
    title: 'Vote',
    category: 'Category',
    defi: 'Defi',
    description: 'Vote for projects you would like to see on Mito Launchpad',
    currentVotes: 'Current Votes',
    successToast: 'Voted Successfully!',

    whitelistedCount: ({ named }: I18nMessageFunction) =>
      `Whitelisted address: (${named('count')})`,

    cta: 'Vote',
    votingOver: 'Voting over',
    votedCta: 'Your choice!',
    notWhitelisted: 'Not whitelisted'
  }
}
