export enum IDOProjectField {
  ProjectTokenDenom = 'project-token-denom',
  QuoteDenom = 'quote-denom',
  StartTimestamp = 'start-timestamp',
  EndTimestamp = 'end-timestamp',
  MasterAddress = 'master-address',
  ProjectTokenAmount = 'project-token-amount',
  Price = 'price',
  HardCap = 'hard-cap', // maximum-contribution
  LockPriceDurationBeforeStart = 'lock-price-before-start',
  TargetRaiseAmount = 'target-raise-amount',
  MinPriceTickSize = 'min-price-tick-size',

  BaseDecimals = 'base-decimals',
  QuoteDecimals = 'quote-decimals',
  ListingFee = 'listing-fee',

  // CPMM
  OrderDensity = 'order-density',
  NotionalValueCap = 'notional-value-cap',

  QuotePythPriceFeedId = 'quote-pyth-price-feed-id',
  IsLaunchingWithVault = 'is-launching-with-vault',
  IsEnableVestingSchedule = 'is-enable-vesting-schedule',
  IsRemoveStakingRequirement = 'is-remove-staking-requirement',
  UseWhitelist = 'use-white-list',

  // Vesting schedule
  VestingScheduleType = 'vesting-schedule-type',
  PiecewiseLinearFirstVestTime = 'piecewise-linear-start-duration',
  PiecewiseLinearFirstVestAmount = 'piecewise-linear-start-amount',
  PiecewiseLinearSecondVestTime = 'piecewise-linear-end-duration',
  PiecewiseLinearSecondVestAmount = 'piecewise-linear-end-amount',
  SaturatingLinearDuration = 'vesting-user-token-duration',
  SaturatingLinearDelay = 'vesting-user-token-delay',

  // Visual only
  BaseSymbol = 'base-symbol',
  QuoteSymbol = 'quote-symbol',
  ProjectTokenTicker = 'project-token-ticker',
  Description = 'description',
  Website = 'website',
  Twitter = 'twitter'
}

export enum MarketField {
  Ticker = 'ticker',
  QuoteDenom = 'base-denom',
  BaseDenom = 'quote-denom',
  ListingFee = 'listing-fee',
  MinNotional = 'min-notional',
  MakerFeeRate = 'maker-fee-rate',
  TakerFeeRate = 'taker-fee-rate',
  MinPriceTickSize = 'min-price-tick-size',
  MinQuantityTickSize = 'min-quantity-tick-size'
}

export enum SubscribeFormField {
  BaseDenom = 'baseDenom',
  BaseAmount = 'baseAmount',
  QuoteDenom = 'quoteDenom',
  MarginRatio = 'marginRatio',
  QuoteAmount = 'quoteAmount',
  SubscriptionType = 'subscriptionType'
}

export enum SwapFormField {
  InputDenom = 'inputDenom',
  InputAmount = 'inputAmount',
  OutputDenom = 'outputDenom',
  OutputAmount = 'outputAmount',
  Slippage = 'slippage',
  QueryError = 'queryError',
  InputLastTradedPrice = 'inputLastTradedPrice',
  OutputLastTradedPrice = 'outputLastTradedPrice'
}

export enum RedeemFormField {
  RedemptionType = 'redemptionType',
  LpTokenBurnAmount = 'lpTokenBurnAmount'
}

export enum SubscribeEmailFormField {
  Email = 'email'
}

export enum StakeFormField {
  Amount = 'amount'
}

export enum UnstakeFormField {
  Amount = 'amount'
}

export enum IDOSubscribeFormField {
  Amount = 'amount'
}

export enum CPMMConfigField {
  // pub base_decimals: u8,
  // pub quote_decimals: u8,
  FeeBps = 'fee-bps',
  OrderType = 'order-type',
  PricingStrategy = 'pricing-strategy',
  PricingStrategyType = 'pricing-strategy-type',
  PricingStrategyAmount = 'pricing-strategy-amount',
  MaxPriceSensitivityBps = 'max-price-sensitivity-bps',
  PricingStrategyBidRange = 'pricing-strategy-bid-range',
  PricingStrategyAskRange = 'pricing-strategy-ask-range',
  MaxInvariantSensitivityBps = 'max-invariant-sensitivity-bps',
  SmoothingPricingWithRelativePriceRange = 'smoothing-pricing-with-relative-price-range'
}

export enum VaultConfigField {
  // Common
  Address = 'address',
  OracleType = 'oracle-type',
  BaseDecimals = 'base-decimals',
  QuoteDecimals = 'quote-decimals',
  OracleStaleTime = 'oracle-stale-time',

  // Base config
  MarketId = 'market-id',
  FeeRecipient = 'fee-recipient',
  OrderDensity = 'order-density',
  NotionalValueCap = 'notional-value-cap',

  // Market making config
  ReservationPriceSensitivityRatio = 'reservation-price-sensitivity-ratio',
  ReservationSpreadSensitivityRatio = 'reservation-spread-sensitivity-ratio',
  MaxActiveCapitalUtilizationRatio = 'max-active-capital-utilization-ratio',
  HeadChangeToleranceRatio = 'head-change-tolerance-ratio',
  HeadToTailDeviationRatio = 'head-to-tail-deviation-ratio',
  SignedMinHeadToFairPriceDeviationRatio = 'signed-min-head-to-fair-price-deviation-ratio',
  SignedMinHeadToTobDeviationRatio = 'signed-min-head-to-tob-deviation-ratio',
  MinOracleVolatilitySampleSize = 'min-in-oracle-volatility-sample-size',
  OracleVolatilityMaxAge = 'oracle-volatility-max-age',
  DefaultMidPriceVolatilityRatio = 'default-mid-price-volatility-ratio',
  MinVolatilityRatio = 'min-volatility-ratio',
  EmergencyOracleVolatilitySampleSize = 'emergency-oracle-volatility-sample-size',
  LastValidMarkPrice = 'last-valid-mark-price',

  // Off Chain
  WithdrawalAdminFeeBps = 'withdrawal-admin-fee-bps',
  InjRewardCommissionBps = 'inj-reward-commission-bps',

  // CPMM
  BaseAmount = 'base-amount',
  QuoteAmount = 'quote-amount',
  BaseUsdPrice = 'base-usd-price',
  BaseOracleSymbol = 'base-oracle-symbol',
  QuoteOracleSymbol = 'quote-oracle-symbol',

  // Spot
  TargetBaseWeight = 'target-base-weight',
  AllowedSpotRedemptionType = 'allowed-spot-redemption-type',

  // Derivative
  Leverage = 'leverage',
  MinProximityToLiquidation = 'min-proximity-to-liquidation',
  AllowedDerivativeRedemptionType = 'allowed-derivative-redemption-type',

  // Instantiate
  Label = 'label',
  PositionPnLPenalty = 'position-pnl-penalty',

  // Visual only
  BaseDenom = 'base-denom',
  QuoteDenom = 'quote-denom'
}

export enum GaugeField {
  EndTime = 'end-time',
  StartTime = 'start-time',
  DenomOne = 'denom-one',
  DenomTwo = 'denom-two',
  AmountOne = 'amount-one',
  AmountTwo = 'amount-two'
}

export type GaugeForm = Record<GaugeField, any>
export type SwapForm = Record<SwapFormField, any>
export type StakeForm = Record<StakeFormField, any>
export type RedeemForm = Record<RedeemFormField, any>
export type UnstakeForm = Record<UnstakeFormField, any>
export type IDOMarketForm = Record<MarketField, any>
export type SubscribeForm = Record<SubscribeFormField, any>
export type IDOSubscribeForm = Record<IDOSubscribeFormField, any>
export type SubscribeEmailForm = Record<SubscribeEmailFormField, any>
export type IDOProjectForm = Record<
  IDOProjectField | CPMMConfigField | VaultConfigField,
  any
>
export type VaultConfigForm = Record<VaultConfigField | CPMMConfigField, any>
