export default {
  portfolio: {
    description: 'Manage your assets and rewards in one place',
    totalValueInVaults: 'Total Value in Vaults',
    pnlExcludeRewards: 'P&L (Exclude Rewards)',
    pnl: 'Overall P&L',
    table: {
      empty: 'You have no holdings'
    },
    myHoldings: 'My Holdings',
    myActivity: 'My Activity',
    holdings: 'Holdings',
    activities: 'Activities',
    rewardActivities: 'Reward Activities'
  }
}
