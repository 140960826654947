import {
  MainPages,
  IDOSubPage,
  VaultSubPage,
  ManageSubPage,
  SettingsSubPage,
  MissionsSubPage,
  PortfolioSubPage
} from '@/types'

export const pageTitle = {
  [MainPages.Swap]: 'Swap',
  [MainPages.Manage]: 'Manage',
  [MainPages.Rewards]: 'Rewards',
  [MainPages.Missions]: 'Missions',
  [MainPages.Launchpad]: 'Launchpad',
  [MainPages.Portfolio]: 'Portfolio',

  [IDOSubPage.Details]: 'Launchpad details',
  [IDOSubPage.Subscription]: 'Launchpad subscription',
  [IDOSubPage.Subscribers]: 'Launchpad subscribers',
  [IDOSubPage.SalesResults]: 'Launchpad sales results',
  [IDOSubPage.Whitelist]: 'Launchpad whitelist',

  [ManageSubPage.CreateLaunchpad]: 'Create launchpad',
  [ManageSubPage.CreateMarket]: 'Create market',
  [ManageSubPage.CreateCPMM]: 'Create CPMM Vault',
  [ManageSubPage.Projects]: 'Launchpad projects',

  [MissionsSubPage.Leaderboard]: 'Mission leaderboard',
  [MissionsSubPage.Progress]: 'Mission progress',

  [PortfolioSubPage.Liquidities]: 'Portfolio liquidities',
  [PortfolioSubPage.RewardActivities]: 'Portfolio reward activities',
  [PortfolioSubPage.SwapHistory]: 'Portfolio swap history',

  [SettingsSubPage.Staking]: 'Update staking contract config',
  [SettingsSubPage.Allocator]: 'Update allocator contract config',
  [SettingsSubPage.CPMMAllowance]: 'Grant CPMM vault creation allowance',

  [VaultSubPage.Overview]: 'Vault overview',
  [VaultSubPage.Info]: 'Vault info',
  [VaultSubPage.VaultConfig]: 'Vault config',
  [VaultSubPage.Liquidity]: 'Vault liquidities',
  [VaultSubPage.Activities]: 'Vault activities',
  [VaultSubPage.VaultTrades]: 'Vault trades',
  [VaultSubPage.VaultOrders]: 'Vault orders'
} as Record<string, string>
