import { I18nMessageFunction } from '../../../types'
import { GALXE_BADGE_ID } from '../../../app/utils/constant'

export default {
  missions: {
    banner: {
      description:
        'Complete Mito missions to get XP points, new surprises and level up your ranking!'
    },
    table: {
      title: 'All Missions',
      xp: 'XP',
      mission: 'Mission',
      progress: 'Progress',
      duration: 'Duration',
      frequency: 'Frequency',
      goal: 'Goal'
    },
    status: {
      complete: 'Complete',
      incomplete: 'Incomplete'
    },
    tooltip: {
      frequency: 'Daily missions cut off time is 12am UTC everyday'
    },
    lastUpdated: ({ named }: I18nMessageFunction) =>
      `Last updated at ${named('date')}`,

    modalConcluded: {
      title: 'Thank you for participating in Mito Missions!',
      description:
        'The points distribution for Mito Missions has concluded for now. Everyone who has participated to date will gain first entry into the Mito Launchpad.',
      descriptionTwo:
        'Stay tuned for upcoming news on how you can get early access on new launches.',
      cta: 'Follow on Twitter',
      mitoLaunchpad: 'Mito Launchpad'
    },

    progress: {
      yourProgress: 'Your Progress',
      viewYourProgress: 'View your progress',
      totalXP: 'Total XP',
      overallRanking: 'Overall Ranking',
      nextMilestone: 'Next milestone',
      crushingIt: "You're crushing it",
      table: {
        title: 'XP History',
        xpCount: 'XP Count',
        date: 'Date'
      }
    }
  },

  mission: {
    frequency: {
      daily: 'Daily',
      oneOff: 'One-off'
    },
    dailyDescription: 'Unlock maximum points by completing this mission daily.',
    [GALXE_BADGE_ID]: {
      title: 'Claim Supporter badge on Galxe',
      description:
        'As one of members of the who have participated in the Mito Missions, a supporter badge is now available exclusively for those who completed a mission.',
      cta: 'Claim on Galxe'
    },
    vaultSubscription: {
      title: 'Subscribe to any Mito Vault',
      description:
        'Subscribe at least $1,000 of any asset to a Mito vault. You must allocate the full amount to one or more vaults to complete this mission. '
    },
    vaultTvl: {
      title: 'Portfolio value above $20000',
      description:
        'If a short description is needed it will be here lorem ipsum dolor sit amet, consectetur adie If a short description is needed it will be here lorem ipsum dolor sit amet, consectetur adie'
    },
    stakingLP: {
      title: 'Stake any asset',
      description:
        'Commit a minimum of $1000 in value of LP tokens to staking. You may stake at least $1000 in value of one or more LP tokens to complete this mission.'
    },
    claimRewards: {
      title: 'Claim staking rewards',
      description:
        'Collect your staking rewards from Vaults where you have staked your LP tokens. You may claim any desired amount from any vault to complete this mission.'
    },
    swaps: {
      title: 'Swap tokens at least five times',
      description:
        'If a short description is needed it will be here lorem ipsum dolor sit amet, consectetur adie If a short description is needed it will be here lorem ipsum dolor sit amet, consectetur adie'
    },
    claimFaucetINJ: {
      title: 'Claim testnet INJ',
      description:
        'Claim testnet assets by selecting "Get Testnet INJ" after clicking on your wallet address in the upper-right corner. You will need testnet funds to use Mito.Acquire testnet assets by selecting "Get Testnet INJ" after clicking on your wallet address in the upper-right corner.'
    }
  },

  leaderboard: {
    title: 'Leaderboard',
    table: {
      rank: 'Rank',
      totalPoints: 'Total Points'
    },
    tablePnl: {
      title: 'Weekly Leaderboard',
      rank: 'Rank',
      address: 'Address',
      pnl: 'PNL',
      volume: '% Volume'
    },

    weekOf: ({ named }: I18nMessageFunction) => `Week of ${named('date')}`,
    ended: ({ named }: I18nMessageFunction) => `Ended: ${named('timestamp')}`,
    weekOfCurrent: ({ named }: I18nMessageFunction) =>
      `Week of ${named('date')} (current)`,
    lastUpdated: ({ named }: I18nMessageFunction) =>
      `Last updated: ${named('timestamp')} ago (Updated hourly)`
  }
}
