import { NETWORK } from '@shared/utils/constant'
import { LocalStorage } from '@injectivelabs/utils'
import { TokenFactoryStatic, TokenStatic } from '@injectivelabs/sdk-ts'
import { tokens } from '@/app/json'

// Services
export const tokenFactoryStatic = new TokenFactoryStatic(
  tokens as TokenStatic[]
)

export const localStorage: LocalStorage = new LocalStorage(`mito-${NETWORK}-v5`)
