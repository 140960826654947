export enum UrlType {
  Twitter = 'twitter',
  Discord = 'discord',
  Telegram = 'telegram'
}

export enum IDOStatus {
  Announced = 'announced',
  Started = 'started',
  Allocating = 'allocating',
  Allocated = 'allocated',
  AllocatedFailed = 'allocated-failed',
  // sorting orders here is important
  Created = 'created',
  Cancelled = 'cancelled'
}

export enum LaunchpadFilterType {
  Oldest = 'oldest',
  Latest = 'latest',
  AllProjects = 'all-projects',
  LiveProjects = 'live-projects'
}

export enum LaunchpadCountdownStatus {
  Starting = 'Staring',
  Live = 'Live',
  Ended = 'Ended'
}

export enum IDOProjectStep {
  TokenInfo = 'token-info',
  CpmmStrategy = 'CPMM-Strategy',
  SalesDetails = 'sales-Details',
  VestingSchedule = 'vesting-schedule',
  // ProjectDetails = 'project-Details',
  ReviewSummary = 'review-summary'
}

export enum WhitelistTab {
  AddressesToAdd = 'addresses-to-add',
  InvalidAddresses = 'invalid-addresses',
  AddressesToRemove = 'addresses-to-remove'
}

export enum CpmmPricingStrategy {
  ConstantPricingWithTickSize = 'ConstantPricingWithTickSize',
  SmoothingPricingWithRelativePriceRange = 'SmoothingPricingWithRelativePriceRange'
}

export enum VestingSchedule {
  SaturatingLinear = 'SaturatingLinear',
  PiecewiseLinear = 'PiecewiseLinear'
}

export enum CPMMOrderType {
  PostOnly = 'PostOnly',
  Vanilla = 'Vanilla'
}
