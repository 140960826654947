export enum CreateCpmmStep {
  Details = 'details',
  Liquidity = 'liquidity',
  Summary = 'summary'
}

export enum RegistrationMode {
  restricted = 'Restricted',
  permissionless = 'Permissionless'
}
