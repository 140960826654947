/* appends trailing slash to route */

export default defineNuxtRouteMiddleware((to) => {
  if (to.fullPath.endsWith('/') || to.fullPath.includes('/?')) {
    return
  }

  if (to.fullPath.includes('?')) {
    return navigateTo(to.fullPath.split('?').join('/?'))
  }

  return navigateTo(`${to.fullPath}/`)
})
