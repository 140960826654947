import { Wallet } from '@injectivelabs/wallet-ts'
import { I18nMessageFunction } from '../../../types'

export default {
  wallet: {
    assets: 'Assets',
    popular: 'Popular',
    myAccount: 'My account',
    noAssetFound: 'No Asset',
    totalValue: 'Total Value',
    vaultTokens: 'Vault Tokens',
    otherWallets: 'Other wallets',
    activeAccount: 'Active account',
    noVaultTokensFound: 'No Vault token',
    viewBalancesOnHub: 'View balances on Hub',
    getTestnetInj: 'Get Testnet INJ!',

    // new
    title: 'Connect wallet',
    connectToWallet: 'Connect to wallet',
    connectYourWallet: 'Connect your wallet',
    qrCode: 'QR Code',
    address: 'Address',
    connect: 'Connect',
    disconnect: 'Disconnect',
    changeWallet: 'Change Wallet',
    ethereumAddress: 'Ethereum Address',

    connectUsingTorus: 'One-click login with',
    connectUsingBrowser: 'Connect using a browser wallet',
    connectUsingHardware: 'Connect using a hardware wallet',

    successfullyConnected: 'Successfully Connected',

    hardwareWallet: {
      getAddresses: 'Get addresses',
      getMoreAddresses: 'Get more addresses',
      selectAddressToConnect: 'Select address to connect',
      getAddressNote: 'We are getting your addresses, please wait ...'
    },

    trezor: {
      note: 'Note: To ensure smooth process while connecting your Trezor Hardware Wallet, please ensure you are running the on latest Chrome version, have your Trezor device connected and unlocked.'
    },

    ledger: {
      ledgerLive: 'Ledger Live',
      ledgerLegacy: 'Ledger Legacy',
      derivationPath: 'Derivation Path',
      selectDerivationPath: 'Select Derivation Path',
      note: 'Note: To ensure smooth process while connecting your Ledger Hardware Wallet, please ensure you are running the on latest Chrome version, have your Ledger device connected, unlocked and your Ethereum app open.'
    },

    option: {
      [Wallet.Leap]: 'Leap',
      [Wallet.Keplr]: 'Keplr',
      [Wallet.Torus]: 'Torus',
      [Wallet.Ninji]: 'Ninji',
      [Wallet.Ledger]: 'Ledger',
      [Wallet.Trezor]: 'Trezor',
      [Wallet.Phantom]: 'Phantom',
      [Wallet.Metamask]: 'Metamask',
      [Wallet.OkxWallet]: 'OKX Wallet',
      [Wallet.BitGet]: 'Bitget Wallet',
      [Wallet.TrustWallet]: 'Trust Wallet',
      [Wallet.Cosmostation]: 'Cosmostation',
      [Wallet.WalletConnect]: 'Wallet Connect'
    },

    terms: {
      title: 'Acknowledge Terms',
      description: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          'By connecting your wallet, you acknowledge that you have read and agree to the ',
          named('termsOfUse'),
          ' and ',
          named('privacyPolicy'),
          '.'
        ]),
      content: {
        title: 'In addition, you hereby represent, warrant, and agree that:',
        acknowledge1:
          'You are not a person or company who is a resident of, is located, incorporated, or has a registered agent in, the United States of America (with respect to trading perpetual contracts), the UK (unless you are an Investment Professional), or a Restricted Territory (as defined in the Mito Terms of Use).',
        acknowledge2:
          'You will not now or in the future access this site or use mito.fi while located in the United States of America (with respect to trading perpetual contracts), the UK (unless you are an Investment Professional), or a Restricted Territory (as defined in the Mito Terms of Use).',
        acknowledge3:
          'You are not using, and will not in the future use, a virtual private network or other means to mask your physical location from a Restricted Territory.',
        acknowledge4:
          'You are lawfully permitted to access this site and trade on mito.fi under the laws of the jurisdiction in which you reside and are located.',
        acknowledge5:
          'You understand the risks associated with using leverage, entering into perpetual contracts, and trading in digital assets.'
      }
    }
  }
}
