import { MAINTENANCE_ENABLED } from '@/app/utils/constant'
import { MainPages } from '@/types'

export default defineNuxtRouteMiddleware((to) => {
  if (to.name !== MainPages.Maintenance && MAINTENANCE_ENABLED) {
    return navigateTo({ name: MainPages.Maintenance })
  }

  if (to.name === MainPages.Maintenance && !MAINTENANCE_ENABLED) {
    return navigateTo({ name: MainPages.Index })
  }
})
