import { I18nMessageFunction, HomeCategory } from '../../../types'

export default {
  home: {
    description: {
      [HomeCategory.NewToCrypto]:
        'Simplify your crypto trading with automated tools and\nsmart contracts.',
      [HomeCategory.CryptoEnthusiast]:
        'Mito is a groundbreaking Web3 protocol that revolutionizes\nautomated trading, launchpads, RWAs and real yield generation.'
    },

    comingSoon: {
      title: 'Coming soon.'
    },

    common: {
      joinUs: 'Join us'
    },

    category: {
      title: 'Customize your experience:',
      newToCrypto: 'New to Crypto',
      cryptoEnthusiast: 'Crypto Enthusiast',
      deFiExpert: 'DeFi Expert'
    },

    whatIsMito: {
      title: 'What Is Mito',
      cta: 'Get started now',
      automatedTradingVault: {
        title: {
          [HomeCategory.NewToCrypto]: 'Automated Trading Vaults',
          [HomeCategory.CryptoEnthusiast]: 'Automated Trading Vaults'
        },

        description: {
          [HomeCategory.NewToCrypto]:
            "Mito's Automated Trading Vaults generate passive returns on your crypto portfolio.",
          [HomeCategory.CryptoEnthusiast]:
            'Optimize crypto returns using smart contract-powered trading vaults'
        }
      },
      injBoost: {
        title: {
          [HomeCategory.NewToCrypto]: '',
          [HomeCategory.CryptoEnthusiast]: 'Launchpad'
        },
        description: {
          [HomeCategory.NewToCrypto]:
            'Each vault aims to increase value through earned trading fees and project rewards.',
          [HomeCategory.CryptoEnthusiast]:
            'Get exclusive access before the hottest projects release via Mito’s Launchpad'
        }
      },
      tvl: {
        title: {
          [HomeCategory.NewToCrypto]: 'Additional rewards granted',
          [HomeCategory.CryptoEnthusiast]: 'Earn rewards'
        },
        description: {
          [HomeCategory.NewToCrypto]: 'For long term participation',
          [HomeCategory.CryptoEnthusiast]: 'For staking and LP'
        }
      }
    },

    automatedTradingVaults: {
      title: 'Trading Vaults',
      description: {
        [HomeCategory.NewToCrypto]:
          "Mito's Automated Trading Vaults simplify your crypto trading and can auto-generate returns.",
        [HomeCategory.CryptoEnthusiast]:
          'Mito’s Automated Trading Vaults allow you to participate in our automated, smart contract-powered ecosystem and collect rewards.'
      },

      one: {
        [HomeCategory.NewToCrypto]: 'Join Vaults',
        [HomeCategory.CryptoEnthusiast]: 'Access Vaults',
        description: {
          [HomeCategory.NewToCrypto]:
            'Deploy individual assets or pair into your preferred Mito trading vault',
          [HomeCategory.CryptoEnthusiast]:
            'Access vaults using stablecoins or pairs of your preferred crypto assets'
        }
      },
      two: {
        [HomeCategory.NewToCrypto]: 'Get Mito Vault Token',
        [HomeCategory.CryptoEnthusiast]: 'Mito LP Token',
        description: {
          [HomeCategory.NewToCrypto]:
            'Receive a Mito Vault token to represent your holdings',
          [HomeCategory.CryptoEnthusiast]:
            'Obtain a mito LP token to represent your holdings'
        }
      },
      three: {
        [HomeCategory.NewToCrypto]: 'Stake for Rewards',
        [HomeCategory.CryptoEnthusiast]: 'Stake for Rewards',
        description: {
          [HomeCategory.NewToCrypto]:
            'Stake your vault token to maximize your rewards',
          [HomeCategory.CryptoEnthusiast]:
            'Stake your LP token to maximize your rewards'
        }
      }
    },

    launchpad: {
      title: 'Launchpad',

      description: {
        [HomeCategory.NewToCrypto]:
          "Receive early access and discover the hottest new crypto projects through Mito's Launchpad.",
        [HomeCategory.CryptoEnthusiast]:
          "Mito's exclusive Launchpad grants early access to new tokens and enhances their liquidity."
      },

      one: {
        [HomeCategory.NewToCrypto]: 'Projects',
        [HomeCategory.CryptoEnthusiast]: 'Projects',
        description: {
          [HomeCategory.NewToCrypto]:
            'Eligible users may participate and subscribe to Launchpad projects',
          [HomeCategory.CryptoEnthusiast]:
            'Eligible users may participate and subscribe to Launchpad projects'
        }
      },
      two: {
        [HomeCategory.NewToCrypto]: 'Tokens',
        [HomeCategory.CryptoEnthusiast]: 'Tokens',
        description: {
          [HomeCategory.NewToCrypto]:
            'Get immediate full custody over your Launchpad tokens',
          [HomeCategory.CryptoEnthusiast]:
            'Get immediate full custody over your Launchpad tokens'
        }
      },
      three: {
        [HomeCategory.NewToCrypto]: 'Rewards',
        [HomeCategory.CryptoEnthusiast]: 'Rewards',
        description: {
          [HomeCategory.NewToCrypto]: 'Enter LP vaults to amplify your rewards',
          [HomeCategory.CryptoEnthusiast]:
            'Enter LP vaults to amplify your rewards'
        }
      }
    },

    dna: {
      title: 'The Mito DNA',
      description: {
        [HomeCategory.NewToCrypto]:
          'Unlock the potential of your crypto portfolio.\nExplore the possibilities by joining a Mito vault and let your assets start working for you. ',
        [HomeCategory.CryptoEnthusiast]:
          'Mito brings access to advanced on-chain products typically reserved for major institutions, democratizing finance for all.'
      },

      card: {
        title: 'Unrivaled Security',
        description: 'Battle-tested and audited by the best'
      }
    },

    trendingVaults: {
      title: 'Trending Vaults'
    },

    footer: {
      privacyPolicy: 'Privacy policy',
      termsOfUse: 'Terms of use',
      description:
        'Jump start your crypto portfolio with smart contract-enabled strategies and earn rewards on autopilot.',
      copyRight: ({ named }: I18nMessageFunction) => `© ${named('year')}`
    },

    docs: 'Docs',
    blog: 'Blog',
    launchApp: 'Launch App'
  }
}
