import { defineStore } from 'pinia'
import { streamProvider } from '@/app/providers/StreamProvider'
import { GeoLocation, UserPreference } from '@/types'

type AppStoreState = {
  owner: boolean
  devMode: boolean
  geoLocation: GeoLocation
  userPreference: UserPreference
}

const initialStateFactory = (): AppStoreState => ({
  owner: false,
  devMode: false,

  geoLocation: {
    continent: '',
    country: '',
    browserCountry: '',
    vpnCheckTimestamp: 0
  },

  userPreference: {
    showMyVaultsOnly: false,
    skipExperimentalModal: false,
    showLowPerformingVaults: false
  }
})

export const useAppStore = defineStore('app', {
  state: (): AppStoreState => initialStateFactory(),
  getters: {
    hasAdminPrivileges: (state) => state.devMode || state.owner
  },
  actions: {
    cancelAllStreams(streamsToPersist: string[] = []) {
      streamProvider.cancelAll(streamsToPersist)
    },

    reset() {
      const appStore = useAppStore()

      appStore.cancelAllStreams()

      appStore.$patch({
        owner: false,
        devMode: false,
        userPreference: {
          skipExperimentalModal: false,
          showLowPerformingVaults: false
        }
      })
    }
  }
})
