import {
  I18nMessageFunction,
  RewardActivityAction,
  StakingPoolFilterType
} from '../../../types'

export default {
  reward: {
    live: 'Live',
    stake: 'Stake',
    claim: 'Claim',
    unlock: 'Unlock',
    staked: 'Staked',
    rewards: 'Rewards',
    withdraw: 'Withdraw',
    unstaking: 'Unstaking',
    stakeMore: 'Stake more',
    myRewards: 'My Rewards',
    noRewards: 'No rewards',
    viewVault: 'View vault',
    claimStake: 'Claim stake',
    claimRewards: 'Claim Rewards',
    viewMyRewards: 'View my rewards',
    totalLiquidity: 'Total liquidity',
    claimableAssets: 'Claimable assets',
    claimAllRewards: 'Claim all rewards',
    claimableRewards: 'Claimable rewards',
    unlockingProgress: 'Unlocking Progress',
    projectedRewardApr: 'Projected reward APR',
    yourStakedLiquidity: 'Your Staked Liquidity',
    totalStakedLiquidity: 'Total staked liquidity',
    totalClaimableRewards: 'Total Claimable Rewards',
    claimableRewardsBreakdown: 'Claimable rewards breakdown',
    claimingLockedFor: ({ named }: I18nMessageFunction) =>
      `Claiming Locked for ${named('countdown')}`,

    filters: {
      includingZeroRewards: 'Including zero rewards',
      [StakingPoolFilterType.APRHigh]: 'APR high to low',
      [StakingPoolFilterType.APRLow]: 'APR low to high',
      [StakingPoolFilterType.LiquidityHigh]: 'Liquidity high to low',
      [StakingPoolFilterType.LiquidityLow]: 'Liquidity low to high'
    },

    activities: {
      table: {
        time: 'Time',
        vault: 'Vault'
      },
      action: {
        [RewardActivityAction.Stake]: 'Stake',
        [RewardActivityAction.Unstake]: 'Unstake',
        [RewardActivityAction.ClaimStake]: 'Claim LP',
        [RewardActivityAction.ClaimReward]: 'Claim Reward'
      }
    },

    toast: {
      claimAllRewardsSuccess: 'Claimed all rewards successfully!'
    },

    tooltip: {
      aprBreakdown: 'Rewards APR Breakdown',
      claimStakeDisabled:
        'Once your unstaking period is done you can claim the tokens.'
    },

    unstake: {
      title: 'Unstake',
      inProgress: 'In progress',
      remainingTime: 'Remaining Time',
      readyToClaim: 'Complete. Ready to claim'
    },

    modal: {
      common: {
        goToPortfolio: 'Go to portfolio',
        backToPortfolio: 'Back to Portfolio',
        backToRewards: 'Back to rewards'
      },

      claimReward: {
        confirmation: {
          successfulTitle: 'Claimed successfully!',
          successToastWithClaimedCoins: ({
            named,
            interpolate
          }: I18nMessageFunction) =>
            interpolate(['You have claimed ', named('coins'), '.'])
        }
      },

      claimUnstake: {
        confirmation: {
          successfulTitle: 'Withdraw successfully!',
          successToastWithClaimedCoins: ({
            named,
            interpolate
          }: I18nMessageFunction) =>
            interpolate(['You have withdrew ', named('coins'), '.'])
        }
      },

      stake: {
        title: 'Earn rewards',
        description: 'Stake your vault tokens to further earn rewards.',
        currentAPR: 'Current APR',
        confirmation: {
          title: 'Summary',
          stakeAmount: 'Stake Amount',
          successfulTitle: 'Staked successfully!',
          successfulToast: ({ named }: I18nMessageFunction) =>
            `You have staked ${named('amount')} ${named('symbol')}.`
        }
      },

      unstake: {
        title: 'Unstake vault tokens',
        period: 'Unstake Period',
        description:
          "Once you initiate the unstaking process, rewards accumulation halts instantly. Also note, there's a {duration} cooling-off period before you can withdraw your unstaked tokens",
        rewardsToBeClaimed: ' Rewards to be claimed',
        confirmation: {
          title: 'Summary',
          successfulTitle: 'Unstaked succesfully!',
          successfulToast: ({ named }: I18nMessageFunction) =>
            `You have unstaked ${named('amount')} ${named('symbol')}.`,
          successToastWithClaimedCoins: ({
            named,
            interpolate
          }: I18nMessageFunction) =>
            interpolate([
              'Your remaining rewards of ',
              named('coins'),
              ' have been added to your wallet.'
            ])
        }
      }
    }
  },

  allocator: {
    title: 'Allocator',
    updateAllocatorContractConfig: 'Updated allocated contract config'
  },

  staking: {
    title: 'Staking',
    updatedStakingContractConfig: 'Updated staking contract config'
  }
}
