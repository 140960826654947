import {
  Page,
  MainPages,
  IDOSubPage,
  VaultSubPage,
  ManageSubPage
} from '@/types'

export const idoConnectedRequiredRoutes = [
  IDOSubPage.Whitelist,
  IDOSubPage.Subscription,
  IDOSubPage.SalesResults
]

export const manageConnectedRequiredRoutes = [
  ManageSubPage.CreateLaunchpad,
  ManageSubPage.Projects,
  ManageSubPage.CreateCPMM,
  ManageSubPage.CreateMarket
]

export const vaultConnectedRequiredRoutes = [
  VaultSubPage.Rewards,
  VaultSubPage.VaultConfig
]

export const walletConnectedRequiredRoutes = [
  ...idoConnectedRequiredRoutes,
  ...vaultConnectedRequiredRoutes,
  ...manageConnectedRequiredRoutes,
  MainPages.Vote,
  MainPages.Manage,
  MainPages.Missions,
  MainPages.Portfolio
] as Page[]
