export enum DerivativeRedemptionType {
  QuoteOnly = 'QuoteOnly',
  PositionAndQuote = 'PositionAndQuote'
}

export enum SpotRedemptionType {
  BaseOnly = 'BaseOnly',
  QuoteOnly = 'QuoteOnly',
  BaseAndQuote = 'BaseAndQuote',
  FixedBaseAndQuote = 'FixedBaseAndQuote',
  VariableBaseAndQuote = 'VariableBaseAndQuote'
}

export enum DerivativeRedemptionMap {
  QuoteOnly = 1,
  PositionAndQuote = 2
}

export enum SpotRedemptionMap {
  BaseAndQuote = 1
  // BaseOnly = 1,
  // QuoteOnly = 2,
  // BaseAndQuote = 4
}

export enum MarketStatus {
  Unspecified = 'unspecified',
  Active = 'active',
  Paused = 'paused',
  Demolished = 'demolished',
  Expired = 'expired'
}

export enum ChartInterval {
  Day = '1D',
  Week = '7D',
  Month = '1M',
  ThreeMonths = '3M',
  SixMonths = '6M',
  Year = '1Y',
  All = 'ALL'
}

export enum VaultChartType {
  TokenPrice = 'tokenPrice',
  TotalValueLocked = 'totalValueLocked'
}

export enum VaultMarketType {
  Spot = 'Spot',
  Derivative = 'Derivative'
}

export enum VaultType {
  CPMM = 'CPMM',
  ASMMSpot = 'ASMMSpot',
  ASMMPerp = 'ASMMPerp',
  OffChain = 'OffChain'
}

export enum VaultContractType {
  OffChain = 'crates.io:managed-vault',
  CPMM = 'crates.io:vault-cpmm-spot',
  ASMMSpot = 'crates.io:vault-asmm-spot',
  ASMMPerp = 'crates.io:vault-asmm-perp'
}

export enum VaultCategoryType {
  All = 'all',
  Managed = 'managed',
  Automated = 'automated'
}

export enum RewardActivityAction {
  Stake = 'stake',
  Unstake = 'unstake',
  ClaimStake = 'claim_stake',
  ClaimReward = 'claim_reward'
}

export enum VaultFilterType {
  TVLHigh = 'tvl-high',
  TVLLow = 'tvl-low',
  APYHigh = 'apy-high',
  APYLow = 'apy-low'
}

export enum StakingPoolFilterType {
  APRHigh = 'apy-high',
  APRLow = 'apy-low',
  LiquidityHigh = 'liquidity-high',
  LiquidityLow = 'liquidity-low'
}

export enum SubscriptionFilterType {
  StakeLow = 'stake-low',
  StakeHigh = 'stake-high',
  TotalValueLow = 'total-value-low',
  TotalValueHigh = 'total-value-high',
  TotalAvailableLow = 'total-available-low',
  TotalAvailableHigh = 'total-available-high'
}
